import Session from 'supertokens-web-js/recipe/session';
import SuperTokens from 'supertokens-web-js';
import EmailPassword from "supertokens-web-js/recipe/emailpassword";

SuperTokens.init({
    appInfo: {
        apiDomain: "https://auth.wave7.cloud",
        apiBasePath: "/auth",
        appName: "auth",
        websiteDomain:"bdl.wave7.cloud",
    },
    recipeList: [
        Session.init({
            exposeAccessTokenToFrontendInCookieBasedAuth: true,
            cookieSameSite: "strict",
        }),
        EmailPassword.init(),
    ],
});
async function getUser() {
    let v8_user = localStorage.getItem("v8_user");
    if (v8_user !== null) {
        let user = JSON.parse(v8_user);
        return await fetch(`https://auth.wave7.cloud/auth/userinfo`, {
            method: 'POST',
        }).then((res) => res.json()).then((response) => {
            if (v8_user !== null) {
                user.user_metadata = response.metadata;
                user.token = response.token;
                //user.role = 'admin'; //todo ADD roles and permissions. Temp admin role for all users
                user.role = user.user_metadata.role;
                localStorage.setItem("v8_user", JSON.stringify(user));
                if (user.user_metadata.exposedTreeNodes !== undefined) {
                    localStorage.setItem("exposedTreeNodes", JSON.stringify(user.user_metadata.exposedTreeNodes));
                }
                return user;
            }
        }).catch((error) => {
            console.log('ST error:', error);
            return user;
        });
    } else {
        return null;
    }
}

export default getUser;
