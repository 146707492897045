export class ImagesControl {
    images = {};

    constructor() {
        let images = localStorage.getItem('local-images');
        this.images = images !== null ? JSON.parse(images) : {};
        for (const prop in this.images) {
            if (this.images.hasOwnProperty(prop) && this.images[prop].includes('blob:')) {
                delete this.images[prop];
            }
        }
    }

    get(key) {
        return this.images[key] !== undefined ? this.images[key] : null;
    }

    getLinkForDB(value) {
        for (const prop in this.images) {
            if (this.images.hasOwnProperty(prop) && this.images[prop] === value) {
                return 'url(' + prop + ')'; // Return the first property name with the specified value
            }
        }
        return value; // Return null if the value is not found in the object
    }

    set(key, value) {
        this.images[key] = value;
        return this.updateLocalStorage();
    }

    async setFromFile(key, file) {
        let scope = this;
        return await this.fileToBackgroundImageUrl(file).then(function (imageUrl) {
            return scope.set(key, `url(${imageUrl})`) ? imageUrl : key;
        });
    }

    delete(key) {
        delete this.images[key];
        return this.updateLocalStorage();
    }

    updateLocalStorage() {
        localStorage.setItem('local-images', JSON.stringify(this.images));
        return true;
    }

    fileToBackgroundImageUrl(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                // The file data is available in e.target.result
                const fileData = e.target.result;

                // Resolve the promise with the data URL
                resolve(fileData);
            };

            // Read the file as a data URL
            reader.readAsDataURL(file);
        });
    }
    async getImageFromClipboard() {
        const itemList = await navigator.clipboard.read();
        let imageType;
        const item = itemList.find(item =>
            item.types.some(type => {
                if (type.startsWith('image/')) {
                    imageType = type;
                    return true;
                }
            })
        );
        const file = item && await item.getType(imageType);
        if (file !== undefined) {
            const url = URL.createObjectURL(file);
            return {'file':file,'url':url};
        }
        return false;
    }
}