import { createStore } from 'vuex';

export default createStore({
  state: {
    selectCollapse: false,
    articleCollapse: false,
    sectionCollapse: false,
    user: null,
    useremail: '',
    loggedin: false,
    isEditable: true,
    startpage: '/',
    startpageloaded: false,
    startpagename: '',
    usertoken: '',
    authModalShow: false,
    count: 2,
    edit: false,
    menu: false,
    disabledDialog: false,
    currentlocation: '',
    article: {
      category: {
        name: 'No Category',
        color: '#bcbcbc',
      },
      title: '',
      subtitle: '',
      data: [],
    },
    newArticle: {},
    isChanged: false,
    targetPath: '',
    treeSelection: [],
    customerSelection: '',
    sectionSelection: '',
    articleSelection: '',
    contentIndex: '',
    contentType: 'txt',
    path: {},
    fromContentEdit: false,
    ifLoading: false,
    ifSaved: false,
    ifError: false,
    breadcrumbPopup: false,
    verticalBreadcrumb: [],
    movePlace: false,
    radioButtonId: null,
    moveToSelect: {},
    modalToComeback: '',
    beforeLoad: false,
    iconsList: fetch('/icons-list.json').then(res => res.json()).then(async function (icons) { return icons})
  },
  mutations: {
    savingPage(state) {
      state.ifLoading = true;
      setTimeout(() => {
        state.ifLoading = false;
      }, 3000);
    },
    pageSaved(state) {
      state.ifLoading = false;
      state.ifSaved = true;
      setTimeout(() => {
        state.ifSaved = false;
      }, 1000);
    },
    savingError(state) {
      console.log('err');
      state.ifLoading = false;
      state.ifSaved = false;
      state.ifError = true;
      setTimeout(() => {
        state.ifError = false;
      }, 1000);
    },
    toggle(state) {
      // mutate state
      state.draggable = !state.draggable;
    },
    updateTargetPath(state, path) {
      state.targetPath = path;
    },
    updateCurrentLocation(state, num) {
      state.currentlocation = num;
    },
    contentEditInfo(state, num) {
      state.contentIndex = num;
      state.contentType = state.article.data[num].name;
    },
    contentTypeEdit(state, type) {
      console.log(type,'type change')
      state.contentType = type;
    },
    deletePost(state, index) {
      state.article.data.splice(index, 1);
    },
    changedPost(state) {
      state.isChanged = true;
      setTimeout(() => {
        state.isChanged = false;
      }, 3000);
    },
    updateStartPageName(state, arr) {
      state.startpagename = arr; 
    },
    updateStartPage(state, arr) {
      state.startpage = arr; 
    },
    updateTreeSelection(state, arr) {
      state.treeSelection = arr;
      this.commit('updateBreadCrumbComponents',state);
    },
    updateSectionSelection(state, resp) {
      state.sectionSelection = resp;
    },
    updateArticleSelection(state, resp) {
      state.articleSelection = resp;
    },
    updateCustomerSelection(state, arr) {
      state.customerSelection = arr;
    },
    updateBreadCrumbComponents(state)
    {
      let temp = [];
      state.path = [];
      state.targetPath = '';
      // console.log(window.innerwidth);
      if(state.treeSelection.length != 0 && state.treeSelection[0]!='Go To') {
        state.treeSelection.forEach(function(item) {
          temp.push(item);
        });
        if (state.sectionSelection !== '') {
          if (localStorage.location.includes(state.sectionSelection[0])) {
            temp = JSON.parse(localStorage.verticalBreadcrumb);
          } else {
            temp.push( state.sectionSelection );
          }
          if (state.articleSelection !== '') {
            temp.push( state.articleSelection );
          }
        }
      } else if (state.treeSelection[0] === 'Go To') {
        temp.push({ 0: 'Home' });
      } else {
        if (localStorage.verticalBreadcrumb != undefined) {
          temp = JSON.parse(localStorage.verticalBreadcrumb);
        }
        if (state.sectionSelection !== '') {
          if (localStorage.location.includes(state.sectionSelection[0])) {
            temp = JSON.parse(localStorage.verticalBreadcrumb);
          } else {
            temp.push(state.sectionSelection);
          }
          if (state.articleSelection !== '') {
            temp.push(state.articleSelection);
          }
        }
      }
      let tempTarget = '';
      temp.forEach(function (line) {
        if(line !== undefined && line!=='') {
          state.path.push(line);
          tempTarget += line[0] + ' / ';
        }
      });
      tempTarget = tempTarget.substring(0, tempTarget.length - 3);
      let tempStr = tempTarget;
      localStorage.verticalBreadcrumb = JSON.stringify(state.path);
      state.verticalBreadcrumb = state.path;
      // if(tempTarget.length > 50) {
      //   tempStr = tempTarget.slice(tempTarget.length-50);
      //   state.targetPath = '...' + tempStr;
      //   localStorage.location = JSON.stringify(state.targetPath);
      // }
      // else {
      state.targetPath = tempStr;
        localStorage.location = JSON.stringify(state.targetPath);
      // }
      if (state.targetPath === 'Home / ') state.targetPath = '';
      // console.log(state.verticalBreadcrumb);
    },
    ifAdmin(state) {
      state.isEditable = true;
    },
    updatePath(state, title) {
      state.targetPath = state.targetPath.replace(title + ' /', '');
      localStorage.location = localStorage.location.replace(title + ' /  ', '');
      const temp = JSON.parse(localStorage.verticalBreadcrumb);
      temp.pop();
      localStorage.verticalBreadcrumb = JSON.stringify(temp);
    }
  },
  actions: {
    save(state, article) {
      state.blankArticle = article;
    },
  },
  getters: {
    getArticle(state) {
      const originalArticle = state.article;
      const article = JSON.parse(JSON.stringify(originalArticle));
      return article;
    },
  },
});
