<template>
  <div class="breadcrumbtree">
    <ul>
      <li v-for="(item, index) in breadcrumb" :key="index" class="functional" :class="item[2] == 1 ? 'link' : 'not_link'"
      @click="item[2] == '1' ? gogo(item[1], index) : null"
      ><a href="#">{{ item[0] }}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbtree',
  data() {
    return {};
  },
  computed: {
    breadcrumb() {
      if (this.$route.name === 'Home') {
        return [{ 0: 'Wave7 Home' }]
      } else if (this.$route.name === 'Select') {
        return [{ 0: 'Home' }]
      } else {
        if (this.$store.state.verticalBreadcrumb.length == 0) {
          return JSON.parse(localStorage.verticalBreadcrumb);
        } else {
          return this.$store.state.verticalBreadcrumb;
        }
      }
    },
  },
  methods: {
    gogo(i, index) {
      // console.log(this.breadcrumb,'breadcrumb')
      this.$router.push({ path: i })
      this.$store.state.breadcrumbPopup = false;
      let amountToDelete = JSON.parse(localStorage.verticalBreadcrumb).length - 1 - index;
      let temp = JSON.parse(localStorage.verticalBreadcrumb);
      let n = amountToDelete;

      while (n > 0) {
        n -= 1;
        temp.pop();
      }
      this.$store.state.treeSelection = [];
      localStorage.verticalBreadcrumb = JSON.stringify(temp);
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbtree {
  overflow-y: auto;
  display: flex;
  ul {
    margin: 0px;
    padding-top: 0px;
    overflow: hidden;
    padding-left: 34px;
  }

  li {
    margin: 8px 0;
    position: relative;
    list-style-type: none;
    a {
      text-decoration: none;
      color: #787878;
      padding-left: 14px;
      display: inline-block;
      width: calc(100% - 20px);
      white-space: nowrap;
    }
  }

  li::before{
    content: '';
    display: block;
    position: absolute;
    left: -13px;
    height: 6px;
    width: 6px;
    background-color: #006C3E;
    border-radius: 3px;
    top: calc(50% - 3px);
  }
  .not_link{
    cursor: default;
    a{
      cursor: default;
    }
  }
  .not_link::before{
    background-color: #BCBCBC;
  }

  li:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    left: -10.5px;
    height: calc(100% - 8.5px);
    bottom: -50%;
    width: 1px;
    background-color: #BCBCBC;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 961px) and (orientation: portrait) {
  .breadcrumbtree {
    top: 0px;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
  .breadcrumbtree {
    ul{
      width: 100%;
      overflow-y: scroll;
    }
  }
}
</style>
